/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js"

import axios from "@/axios.js"
// import firebase from 'firebase/app'
// import 'firebase/auth'
import router from '@/router'

export default {
    ChangePassword(context, payload) {
      return new Promise((resolve,reject) => {
        axios.post("/ChangePassword",payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
             reject(error)
            })
      })
    },

    // JWT
    loginJWT({ commit }, payload) {
      return new Promise((resolve,reject) => {
        jwt.login(payload.userDetails.email, payload.userDetails.password)
          .then(response => {
            // If there's user data in response
            if(response.status == 200) {
              // Navigate User to homepage

              //window.location="/"

              debugger;
              // Set accessToken
              localStorage.setItem("expiresIn", response.data.Data.expiresIn)
              localStorage.setItem("accessToken", response.data.Data.accessToken)
              localStorage.setItem("refreshToken", response.data.Data.refreshToken)


              // Update user details
              commit('UPDATE_USER_INFO', response.data.Data.userData, {root: true})

              // Set bearer token in axios
              commit("SET_BEARER", response.data.Data.accessToken)
              //router.push(router.currentRoute.query.to || '/')
              resolve(response)
            }else {
              reject({message: "Wrong Email or Password"})
            }

          })
          .catch(error => {
               reject(error)
            })
      })
    },
    registerUserJWT({ commit }, payload) {

      const { email, password, confirmPassword } = payload.userDetails

      return new Promise((resolve,reject) => {

        // Check confirm password
        if(password !== confirmPassword) {
          reject({message: "Password doesn't match. Please try again."})
        }

        jwt.registerUser(confirmPassword, email, password)
          .then(response => {
            // Redirect User
            router.push(router.currentRoute.query.to || '/')

            // Update data in localStorage
            localStorage.setItem("accessToken", response.data.accessToken)
            localStorage.setItem("refreshToken", response.data.refreshToken)

            commit('UPDATE_USER_INFO', response.data.userData, {root: true})

            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    fetchAccessToken() {
      return new Promise((resolve) => {
        jwt.refreshToken().then(response => { resolve(response) })
        .catch(function(){
            router.push('/pages/login')
          })
      })
    },
    logout({ commit }) {

       // Set accessToken
       localStorage.removeItem("accessToken")
       localStorage.removeItem("refreshToken")
       localStorage.removeItem("expiresIn")
        // Update user details
        commit('CLEAR_USER_INFO',null, {root: true})

        // Set bearer token in axios
        commit("REMOVE_BEARER")
    },
    ChangeUserLanguage( context,languageId) {
      return axios.get("/Account/ChangeUserLanguage?languageID=" + languageId);
    },
    CreateMeeting(context, Model) {
      return new Promise((resolve, reject) => {
        debugger;
        axios
          .post("API/Meeting/CreateMeeting", Model)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    GetUserMeetingsHistoryList() {
      return new Promise((resolve, reject) => {
        axios
          .get("API/Meeting/GetUserMeetingsHistoryList")
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GetUserMeetingsList() {
      debugger;
      return new Promise((resolve, reject) => {
        axios
          .get("API/Meeting/GetUserMeetingsList")
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    JoinMeeting(context, Model) {
      debugger;
      return new Promise((resolve, reject) => {
        axios
          .get("API/Meeting/JoinMeeting", {
            params: {
              password: Model.password,
              meetingID: Model.meetingID
            }
          })
          .then(response => {
            if (response.status == 200) {
              resolve(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    CreateMeetingSession(context, reservationId) {
      debugger;
      return new Promise((resolve, reject) => {
        axios
          .get("API/PatientReservationSession/CreateMeetingSession?reservationId="+reservationId)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GetRecordings(context, meetingId) {
      return new Promise((resolve, reject) => {
        axios
          .get("API/Meeting/GetRecordings", {
            params: {
              meetingId: meetingId
            }
          })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
}
