// axios
import axios from "axios";


 //const domain = "http://localhost:58870/"
//    //const domain = process.env.VUE_APP_Base_Url
//const domain ="https://backend.doclinia.com/";
   const domain  ="https://api.doclinia.com/";

//const domain = "http://195.201.197.126:30/";

export default axios.create({
  baseURL: domain
  // You can add your headers here
});
